<template>
  <ele-drawer :isFullScreen="true" @on-close="$emit('update:isVisible', false)">
    <div class="container">
      <div class="srceen">
        <AirCesium :previewType="'task'" :previewId="taskId" />
      </div>
      <div class="task_detail">
        <div class="detail_title flex-sb">
          <span>{{ t('398') }}</span>
          <span
            class="iconfont icon-bianjishuru hover_opacity"
            style="color: #24b5ee"
            @click="edit"
            v-checkAuth="'task-task-update'"
            v-if="!editShow"
          ></span>
          <div v-else class="flex-a">
            <div class="btn hover_opacity" @click="editClose">{{ t('217') }}</div>
            <div class="btn btn_left hover_opacity" @click="updateTaskMessage">
              确定
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="detail_content">
          <div class="task_name">
            <!-- 任务名称 -->
            <div class="list flex-a">
              <div class="listName">{{ t('399') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ taskMessage.taskName }}
              </div>
              <div class="listContent" v-else>
                <input
                  class="rolePut"
                  v-model="taskMessage.taskName"
                  :placeholder="t('414')"
                  maxlength="15"
                />
              </div>
            </div>
            <!-- 航线名称 -->
            <div class="list flex-a">
              <div class="listName">{{ t('400') }}</div>
              <div class="listContent">{{ taskMessage.airwayName }}</div>
            </div>
            <!-- 任务所属 -->
            <div class="list flex-a">
              <div class="listName">{{ t('401') }}</div>
              <div class="listContent">{{ taskMessage.taskBind }}</div>
            </div>
            <!-- 执行次数 -->
            <div class="list flex-a">
              <div class="listName">{{ t('402') }}</div>
              <div class="listContent">{{ taskMessage.execCount }}</div>
            </div>
            <!-- 参考执行时间 -->
            <div class="list flex-a">
              <div class="listName">{{ t('403') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ taskMessage.execTime }}
              </div>
              <div class="listContent" v-else>
                <el-time-picker
                  :placeholder="t('1275')"
                  v-model="taskMessage.execTime"
                  value-format="HH:mm:ss"
                  style="width: 20.6875rem"
                ></el-time-picker>
              </div>
            </div>
            <!-- 优先级 -->
            <div class="list flex-a">
              <div class="listName">{{ t('404') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ level }}
              </div>
              <div class="listContent" v-else>
                <el-select v-model="level" :placeholder="t('175')">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>
            <!-- 遥控器断联策略 -->
            <div class="list flex-a">
              <div class="listName">{{ t('405') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ chooseTaskModel }}
              </div>
              <div class="listContent" v-else>
                <el-select v-model="chooseTaskModel" :placeholder="t('175')">
                  <el-option
                    v-for="item in chooseTask"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>
            <!-- rtk，gps -->
            <div class="list flex-a">
              <div class="listName">{{ t('406') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ rtkGps }}
              </div>
              <div class="listContent" v-else>
                <el-select v-model="rtkGps" :placeholder="t('175')">
                  <el-option
                    v-for="item in openOrClose"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>
            <!-- 最大重试次数 -->
            <div class="list flex-a">
              <div class="listName">{{ t('409') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ taskMessage.taskAttributeContent.retryCount }}
              </div>
              <div class="listContent" v-else>
                <el-select
                  v-model="taskMessage.taskAttributeContent.retryCount"
                  :placeholder="t('175')"
                >
                  <el-option label="0" value="0"></el-option>
                  <el-option label="1" value="1"></el-option>
                  <el-option label="2" value="2"></el-option>
                  <el-option label="3" value="3"></el-option>
                  <el-option label="4" value="4"></el-option>
                  <el-option label="5" value="5"></el-option>
                </el-select>
              </div>
            </div>
            <!-- 任务最后执行时间 -->
            <div class="list flex-a">
              <div class="listName">{{ t('1276') }}</div>
              <div class="listContent">
                {{ taskMessage.taskAttributeContent.deadlineTime }}
              </div>
            </div>
            <!-- 创建人 -->
            <div class="list flex-a">
              <div class="listName">{{ t('411') }}</div>
              <div class="listContent">{{ taskMessage.createUserName }}</div>
            </div>
            <!-- 创建时间 -->
            <div class="list flex-a">
              <div class="listName">{{ t('412') }}</div>
              <div class="listContent">{{ taskMessage.createTime }}</div>
            </div>
            <!-- 任务备注 -->
            <div class="list flex-a">
              <div class="listName">{{ t('413') }}</div>
              <div class="listContent" v-if="editShow == false">
                {{ taskMessage.taskRemark }}
              </div>
              <div class="listContent" v-else>
                <el-input
                  v-model="taskMessage.taskRemark"
                  :placeholder="t('1277')"
                  clearable
                  type="textarea"
                  class="taskTipInput"
                  resize="none"
                  maxlength="20"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ele-drawer>
</template>
<script>
import { t } from '../../../../languages';
import { reactive, toRefs, defineComponent, ref } from "vue";
import taskManage from "@/network/task";
import { ElMessage } from "element-plus";
import { Timer } from "@element-plus/icons-vue";
import AirCesium from "./airCesium.vue";

export default defineComponent({
  components: {
    AirCesium,
  },
  props: {
    taskId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: [
        {
          value: "FIRST",
          label: t("583"),
        },
        {
          value: "POSTPONE",
          label: t("1278"),
        },
      ],
      chooseTask: [
        {
          value: "CONTINUE",
          label: t("581"),
        },
        {
          value: "STOP",
          label: t("480"),
        },
      ],
      openOrClose: [
        {
          value: "true",
          label: t("407"),
        },
        {
          value: "false",
          label: t("408"),
        },
      ],
    };
  },
  emits: ["update:isVisible", "updateDate"],
  setup(props, { emit }) {
    // onBeforeMount(() => {
    //   getTaskMessage();
    // });
    console.log(props);
    let state = reactive({
      list: {
        taskName: "",
        taskTime: "",
        level: "",
        chooseWay: "",
        isOpen: "",
        reTry: "",
        deadline: "",
        taskTip: "",
        timeTask: "",
      },
      taskMessage: {
        taskAttributeContent: {
          retryCount: "",
          deadlineTime: "",
        },
        taskName: "",
        taskRemark: "",
      },
      level: "", //优先级
      chooseTaskModel: "", //遥控器断联策略
      rtkGps: "",
    });
    /* 编辑图标 */
    const editShow = ref(false);
    const edit = () => {
      editShow.value = true;
    };
    const editClose = () => {
      editShow.value = false;
      getTaskMessage();
    };
    /* 根据taskId获取任务信息 */
    const { taskId } = toRefs(props);
    const getTaskMessage = async () => {
      const res = await taskManage.getTaskMessage(taskId.value);
      state.taskMessage = res.resultData;
      if (state.taskMessage.taskAttributeContent.taskPriority === "FIRST") {
        state.level = t("583");
      } else {
        state.level = t("1278");
      }
      if (state.taskMessage.taskAttributeContent.remotePolicy === "CONTINUE") {
        state.chooseTaskModel = t("581");
      } else {
        state.chooseTaskModel = t("480");
      }
      if (state.taskMessage.taskAttributeContent.signals === true) {
        state.rtkGps = t("407");
      } else {
        state.rtkGps = t("408");
      }
    };
    getTaskMessage();
    /* 提交任务信息 */

    const updateTaskMessage = async () => {
      if (state.taskMessage.taskName === "") {
        ElMessage.error(t("806"));
        return false;
      }
      if (state.taskMessage.execTime === "") {
        ElMessage.error(t("1279"));
        return false;
      }
      if (state.level === "") {
        ElMessage.error(t("1280"));
        return false;
      }
      if (state.chooseTaskModel === "") {
        ElMessage.error(t("1281"));
        return false;
      }
      if (state.rtkGps === "") {
        ElMessage.error(t("1282"));
        return false;
      }
      if (state.taskMessage.taskAttributeContent.retryCount === "") {
        ElMessage.error(t("1283"));
        return false;
      }
      if (state.taskMessage.taskAttributeContent.deadlineTime === "") {
        ElMessage.error(t("1284"));
        return false;
      }
      if (state.level === t("583")) {
        state.level = "FIRST";
      } else {
        state.level = "POSTPONE";
      }
      if (state.chooseTaskModel === t("581")) {
        state.chooseTaskModel = "CONTINUE";
      } else {
        state.chooseTaskModel = "STOP";
      }
      if (state.rtkGps === t("407")) {
        state.rtkGps = true;
      }
      let data = {
        airwayInfo: state.taskMessage.airwayInfo,
        airwayName: state.taskMessage.airwayName,
        createTime: state.taskMessage.createTime,
        createUserId: state.taskMessage.createUserId,
        createUserName: state.taskMessage.createUserName,
        deleteStatus: state.taskMessage.deleteStatus,
        execCount: state.taskMessage.execCount,
        execTime: state.taskMessage.execTime, //参考执行时间
        taskAttributeContent: {
          retryCount: state.taskMessage.taskAttributeContent.retryCount, //最大重试次数
          deadlineTime: state.taskMessage.taskAttributeContent.deadlineTime, //任务最后执行时间
          taskPriority: state.level, //优先级
          remotePolicy: state.chooseTaskModel, //遥控器断联策略
          signals: state.rtkGps, //rtk,gps信号
        },
        taskBind: state.taskMessage.taskBind,
        taskId: state.taskMessage.taskId,
        taskName: state.taskMessage.taskName, //任务名称
        taskOwner: state.taskMessage.taskOwner,
        taskRemark: state.taskMessage.taskRemark, //任务备注
        taskType: state.taskMessage.taskType,
        updateTime: state.taskMessage.updateTime,
      };
      const res = await taskManage.updateTaskMessage(data);

      if (res.resultStatus) {
        ElMessage({
          message: t("714"),
          type: "success",
        });
        emit("update:isVisible", false);
        emit("updateDate");
      }
    };
    return {
      ...toRefs(state),
      editShow,
      edit,
      editClose,
      Timer,
      getTaskMessage,
      updateTaskMessage,
    };
  },
});
</script>

<style lang="less" scoped>
/deep/ .el-drawer.ltr,
.el-drawer.rtl {
  height: 90%;
  bottom: 0;
}
.container {
  display: flex;
  height: 100%;
  .srceen {
    width: 73%;
    height: 100%;
  }
  .task_detail {
    width: 27%;
    .detail_title {
      padding: 10px 5% 10px 12px;
      .btn {
        width: 5.8125rem;
        height: 2.0625rem;
        background: rgba(0, 28, 49, 0.7);
        border: 1px solid #00f5ff;
        color: #ffffff;
        text-align: center;
        line-height: 2.0625rem;
      }
      .btn_left {
        margin-left: 0.9375rem;
      }
    }
  }
  .line {
    border: 1px solid #003335;
  }
  .detail_content {
    padding: 10px 10px;
    .list {
      height: 2.375rem;
      margin-bottom: 0.8125rem;
      .listName {
        width: 8.6875rem;
        color: #999999;
      }
      .listContent {
        color: #ffffff;
        font-size: 1.125rem;
        // 修改input样式
        .rolePut {
          width: 20.6875rem;
          height: 2.375rem;
          box-sizing: border-box;
          border: 1px solid #00f5ff;
          padding-left: 1.0625rem;
          background: #001c31;
          color: #ffffff;
          outline: 0;
        }
        input::-webkit-input-placeholder {
          color: #a9b8c3;
        }
      }
    }
  }
  //重写el-input样式
  /deep/ .el-input__wrapper {
    width: 20.6875rem;
    border: 1px solid #00f5ff;
    background: #001c31;
    box-shadow: 0 0 0 0;
    border-radius: 0;
  }
  /deep/ .el-input__inner {
    color: #fff;
  }
  //取消el-select自带的hover样式
  /deep/.el-select:hover:not(.el-select--disabled) .el-input__wrapper {
    box-shadow: 0 0 0 0;
  }
  .taskTipInput {
    margin-top: 3.75rem;
    width: 20.6875rem;
    /deep/ .el-input__inner {
      color: #fff;
      margin-top: -30px;
    }
    /deep/.el-textarea__inner {
      height: 5rem;
      border: 2px solid #00f5ff;
      background: #001c31;
      border-radius: 0;
      box-shadow: 0 0 0 0;
      color: #fff;
    }
  }
}
</style>
