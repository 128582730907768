<template>
  <div>
    <div class="container">
      <div class="header flex-sb">
        <div class="title flex">
          <p class="hover_opacity" @click="closeDrawer">{{ t('159') }}&nbsp;</p>
          <p>/ {{ t('803') }}</p>
        </div>
        <div class="flex-sb" style="height: 100%">
          <div
            class="icon_btn blue_btn"
            @click="exportData()"
            v-checkAuth="'task-cycle-export'"
          >
            <el-icon class="element_icon"> <Upload /> </el-icon>&nbsp;{{ t('647') }}
          </div>
          <div
            class="icon_btn red_btn"
            style="margin-top: 2px"
            @click="batchDel"
            v-checkAuth="'task-cycle-delete'"
          >
            <el-icon class="element_icon"> <Delete /> </el-icon>&nbsp;{{ t('210') }}
          </div>
          <div class="icon_btn close hover_opacity" @click="closeDrawer">
            <el-icon>
              <CloseBold />
            </el-icon>
          </div>
        </div>
      </div>
      <div class="nav">
        <search-bar
          class="search"
          :searchPlaceholder="t('1246')"
          width="19.625rem"
          v-model:searchContent="queryForm.keyword"
          @on-search="getTaskCycleList()"
        />
        <el-button
          @click="showNewPlan"
          v-checkAuth="'task-cycle-add'"
          type="primary"
          style="font-size: 10px; margin-left: 1.875rem"
          ><span class="icon_new">+</span> {{ t('1247') }}&nbsp;&nbsp;
        </el-button>
        <ele-select
          :label="t('399')"
          :options="taskSelectList"
          :labelname="'taskName'"
          :value="'id'"
          v-model:selectVal="taskSelectList.id"
          @on-select="getTaskCycleList()"
        />
      </div>
      <div class="table_list">
        <ele-table
          :tableColumnArray="tableColumnArray"
          :tableData="tableData"
          v-model:pageNo="queryForm.pageNo"
          v-model:pageSize="queryForm.pageSize"
          :total="queryForm.total"
          @handleNoChange="getTaskCycleList"
          @handleSizeChange="getTaskCycleList"
          @handleSelectionChange="handleSelectionChange"
        >
          <template v-slot="{ prop, row }">
            <span v-if="prop === 'cycleMode'">
              <span v-if="row.cycleMode === 1">{{ t('1248') }}</span>
              <span v-else-if="row.cycleMode === 2"
                >每{{ row.cycleParamSet }}</span
              >
              <span v-else-if="row.cycleMode === 3">{{
                row.cycleParamSet
              }}</span>
            </span>
            <span v-if="prop === 'activeStartTime'">
              <span>{{ row.activeStartTime }}-{{ row.activeEndTime }}</span>
            </span>
            <span v-if="prop === 'openStatus'">
              <span v-if="row.openStatus === true">{{ t('1249') }}</span>
              <span v-else>{{ t('430') }}</span>
            </span>
          </template>
          <template v-slot:buttonHanlder>
            <el-table-column :label="t('610')" align="center">
              <template v-slot="{ row }">
                <el-row>
                  <el-col :span="12" v-if="!row.openStatus">
                    <span class="green_btn" @click="updateStatus(row.id)"
                      >{{ t('1249') }}</span
                    >
                  </el-col>
                  <el-col :span="12" v-if="row.openStatus">
                    <span class="red_btn" @click="stopStatus(row.id)"
                      >{{ t('430') }}</span
                    >
                  </el-col>
                  <el-col :span="12">
                    <span
                      class="blue_btn"
                      @click="removeTask(row.id, row.openStatus)"
                      >{{ t('392') }}</span
                    >
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </ele-table>
      </div>
    </div>
    <!-- 新建计划弹窗 -->
    <ele-dialog
      v-model:isVisible="planTaskDialogShow"
      :dialogWidth="'620px'"
      :dialogTitle="t('1247')"
      @on-cancel="closeAddNewPlan"
      @on-add="addNewPlan"
    >
      <el-form
        ref="elFormRef"
        :model="newPlan"
        :rules="formRules"
        label-width="80px"
        label-position="top"
        class="el_form"
        @submit.prevent
      >
        <!-- 计划名称 -->
        <el-form-item :label="t('280')" prop="planName">
          <el-input
            v-model="newPlan.planName"
            maxlength="20"
            :placeholder="t('1246')"
            show-word-limit
            type="text"
          />
        </el-form-item>
        <!-- 描述备注 -->
        <el-form-item :label="t('433')" prop="taskDescription">
          <el-input
            v-model="newPlan.taskDescription"
            :placeholder="t('1250')"
            clearable
            type="textarea"
            class="taskTipInput"
            resize="none"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('1251')" prop="taskValue">
          <el-select class="selectTask" v-model="newPlan.taskValue">
            <el-option
              v-for="item in getTaskMenuList"
              :key="item.taskId"
              :label="item.taskName"
              :value="item.taskId"
            />
          </el-select>
        </el-form-item>
        <div class="column_box">
          <div class="box_left">
            <el-form-item :label="t('1252')" prop="cycleMode">
              <el-select v-model="newPlan.cycleMode">
                <el-option
                  v-for="item in getCycleMenuList"
                  :key="item.value"
                  :label="item.desc"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="box_right">
            <div class="demo-date-picker">
              <div class="date_class" style="margin-left: 20px">
                <el-form-item :label="t('1253')" prop="activeStartTime">
                  <el-date-picker
                    v-model="newPlan.activeStartTime"
                    type="date"
                    :placeholder="t('774')"
                    value-format="YYYY-MM-DD"
                  />
                </el-form-item>
              </div>
            </div>
            <span class="split">至</span>
            <div class="demo-date-picker">
              <div class="date_class">
                <el-form-item
                  label=""
                  prop="activeEndTime"
                  class="empty_form_item"
                >
                  <el-date-picker
                    v-model="newPlan.activeEndTime"
                    type="date"
                    :placeholder="t('774')"
                    value-format="YYYY-MM-DD"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <el-form-item :label="t('395')" prop="cycleParamTime">
          <el-time-picker
            v-model="newPlan.cycleParamTime"
            :placeholder="t('436')"
            clearable
            style="width: 581px"
            value-format="HH:mm:ss"
            format="HH:mm:ss"
          />
        </el-form-item>
        <el-form-item
          v-if="newPlan.cycleMode === 2"
          :label="t('1254')"
          prop="weekValue"
        >
          <el-select
            class="selectTask"
            v-model="newPlan.weekValue"
            :placeholder="t('175')"
            multiple
            collapse-tags
          >
            <el-option :label="t('189')" value="Monday"></el-option>
            <el-option :label="t('190')" value="Tuesday"></el-option>
            <el-option :label="t('191')" value="Wednesday"></el-option>
            <el-option :label="t('192')" value="Thursday"></el-option>
            <el-option :label="t('193')" value="Friday"></el-option>
            <el-option :label="t('194')" value="Saturday"></el-option>
            <el-option :label="t('195')" value="Sunday"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="newPlan.cycleMode === 3"
          :label="t('1255')"
          prop="multipleDate"
          class="date_form_item"
        >
          <el-input
            v-model="newPlan.multipleDate"
            :placeholder="t('175')"
            readonly
            @click="showDate = !showDate"
          ></el-input>
          <transition name="el-zoom-in-top">
            <div class="date_box" v-show="showDate">
              <div class="box_header">
                <h4>{{ t('1256') }}</h4>
              </div>
              <ul class="date_ul clearfix">
                <li
                  class="fl"
                  v-for="item in 31"
                  :key="item"
                  :class="{ chooseLi: chooseDateArr.includes(item) }"
                  @click="chooseDate(item)"
                >
                  <div>
                    <span>{{ item }}</span>
                  </div>
                </li>
              </ul>
              <div class="box_footer clearfix">
                <button
                  class="el-button el-button--default el-button--mini is-plain el-picker-panel__link-btn fr"
                  type="button"
                  @click="showDate = false"
                >
                  <span>OK</span>
                </button>
              </div>
            </div>
          </transition>
        </el-form-item>
      </el-form>
    </ele-dialog>
  </div>
</template>
<script>
import { t } from '../../../../languages';
import { defineComponent, onMounted, ref, toRefs, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import download from "@/network/download";
import taskManage from "@/network/task";
import { store } from "@/store";

export default defineComponent({
  data() {
    return {
      tableColumnArray: [
        { label: t("1257"), prop: "taskName" },
        { label: t("399"), prop: "taskName2", width: "200%" },
        { label: t("1258"), prop: "cycleMode", width: "250%" },
        { label: t("1259"), prop: "activeStartTime", width: "200%" },
        { label: t("395"), prop: "cycleParamTime" },
        { label: t("1260"), prop: "openStatus", width: "100%" },
        { label: t("402"), prop: "execCount", width: "100%" },
        { label: t("1261"), prop: "taskRemark" },
      ],
    };
  },
  emits: ["closeDrawer", "reload"],
  setup(props, { emit }) {
    onMounted(() => {
      getTaskCycleList();
    });

    const state = reactive({
      queryForm: {
        keyword: "",
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
      newPlan: {
        activeStartTime: "", //起始时间
        activeEndTime: "", //结束时间
        cycleParamTime: "", //执行时间
        weekValue: "", //选择周几
        cycleMode: "",
        planName: "", //计划名称输入框
        taskDescription: "", //描述备注
        multipleDate: "",
        taskValue: "", //任务下拉
      },
      formRules: {
        planName: [
          { required: true, message: t("1246"), trigger: "blur" },
        ],
        taskDescription: [
          { required: true, message: t("1262"), trigger: "blur" },
        ],
        taskValue: [
          { required: true, message: t("1263"), trigger: "change" },
        ],
        cycleMode: [
          { required: true, message: t("1264"), trigger: "change" },
        ],
        activeStartTime: [
          { required: true, message: t("1265"), trigger: "change" },
        ],
        activeEndTime: [
          { required: true, message: t("1266"), trigger: "change" },
        ],
        cycleParamTime: [
          { required: true, message: t("1267"), trigger: "change" },
        ],
        weekValue: [
          { required: true, message: t("1268"), trigger: "change" },
        ],
        multipleDate: [
          { required: true, message: t("1268"), trigger: "change" },
        ],
      },
      showDate: false,
      chooseDateArr: [],
    });

    const closeDrawer = () => {
      emit("closeDrawer");
      emit("reload");
    };
    //dialog开启
    const planTaskDialogShow = ref(false);
    const showNewPlan = () => {
      planTaskDialogShow.value = true;
      getCycleMenu();
      getTaskMenu();
      getUserInfo();
    };
    //获取登录信息
    const userInfos = ref([]);
    const getUserInfo = () => {
      const userInfo =
        store.state.user.userInfo || localStorage.getItem("userInfo");
      userInfos.value = userInfo;
      console.log(userInfos.value);
    };
    //获取周期任务列表
    const tableData = ref([]);
    const getTaskCycleList = async () => {
      const res = await taskManage.getTaskCycleList({
        downWord: taskSelectList.value.id,
        keyword: state.queryForm.keyword,
        pageNo: state.queryForm.pageNo,
        pageSize: state.queryForm.pageSize,
      });
      if (res.resultStatus) {
        tableData.value = res.resultData.data;
        state.queryForm.total = res.resultData.total;
      }
    };
    //获取任务名称下拉列表
    const taskSelectList = ref([]);
    const getTaskSelectList = async () => {
      const res = await taskManage.getTaskSelectList();
      taskSelectList.value = res.resultData;
    };
    getTaskSelectList();

    /* 勾选Checkbox */
    const checkedIds = ref([]);
    const checkedStatu = ref([]);
    function handleSelectionChange(selection) {
      const ids = [];
      const statu = [];
      for (let i = 0; i < selection.length; i++) {
        ids.push(selection[i].id);
        statu.push(selection[i].openStatus);
      }
      checkedIds.value = ids;
      checkedStatu.value = statu;
    }
    //导出文件
    const exportData = async () => {
      await download.downloadSource(
        "/api/v1/taskCycle/export",
        { keyword: state.queryForm.keyword, downWord: taskSelectList.value.id },
        "application/vnd.ms-excel",
        "get"
      );
    };
    /* 批量删除 */
    function batchDel() {
      if (checkedIds.value.length > 0) {
        ElMessageBox.confirm(
          t("1269"),
          t("130"),
          {
            confirmButtonText: t("218"),
            cancelButtonText: t("217"),
          }
        )
          .then(async () => {
            if (checkedStatu.value.includes(true)) {
              ElMessage.error(t("1270"));
              return false;
            }
            const res = await taskManage.batchDelTask({
              ids: checkedIds.value,
            });
            if (res.resultStatus) {
              ElMessage.success(t("560"));
              getTaskCycleList();
            }
          })
          .catch(() => {
            ElMessage(t("549"));
          });
      } else {
        ElMessage.warning(t("659"));
      }
    }
    /* 更新周期任务开启状态*/
    const status = ref(true);
    const updateStatus = async (id) => {
      const res = await taskManage.upDateCycleTask(id, true);
      if (res.resultStatus) {
        ElMessage.success(t("1271"));
        getTaskCycleList();
      }
    };
    const stopStatus = async (id) => {
      const res = await taskManage.upDateCycleTask(id, false);
      if (res.resultStatus) {
        ElMessage.success(t("1272"));
        getTaskCycleList();
      }
    };
    /*根据id移除任务 */
    const removeTask = (id, openStatus) => {
      ElMessageBox.confirm(t(`确定移除本计划吗？`), t("130"), {
        type: "warning",
        confirmButtonText: t("218"),
        cancelButtonText: t("217"),
      })
        .then(async () => {
          if (openStatus === true) {
            ElMessage.error(t("1273"));
            return false;
          }
          const res = await taskManage.deleteTask(id);
          if (res.resultStatus) {
            ElMessage.success(t("565"));
            getTaskCycleList();
          }
        })
        .catch(() => {
          ElMessage(t("549"));
        });
    };
    /* 获取执行周期下拉列表 */
    const value = ref("");
    const getCycleMenuList = ref([]);
    const getCycleMenu = async () => {
      const res = await taskManage.getCycleMenu();
      getCycleMenuList.value = res.resultData.filter((item, index) => {
        return index > 0;
      });
    };
    /* 获取任务下拉列表 */
    const getTaskMenuList = ref([]);
    const getTaskMenu = async () => {
      const res = await taskManage.getTaskMenu();
      getTaskMenuList.value = res.resultData;
    };

    const elFormRef = ref(null); //表单dom
    /*新建计划 */
    const addNewPlan = async () => {
      elFormRef.value.validate(async (valid) => {
        if (valid) {
          let cycleParam = "0";
          // 根据周期日期时间范围汇集成一个字符串参数
          if (state.newPlan.cycleMode === 2) {
            cycleParam = state.newPlan.weekValue.join(";");
          } else if (state.newPlan.cycleMode === 3) {
            cycleParam = state.newPlan.multipleDate;
          }
          let times = "00:00:00";
          let data = {
            activeStartTime: state.newPlan.activeStartTime + ` ${times}`,
            activeEndTime: state.newPlan.activeEndTime + ` ${times}`,
            createUserId: userInfos.value.id,
            createUserName: userInfos.value.username,
            cycleMode: state.newPlan.cycleMode,
            cycleParamSet: cycleParam,
            cycleParamTime: state.newPlan.cycleParamTime,
            id: "",
            taskId: state.newPlan.taskValue,
            taskName: state.newPlan.planName,
            taskRemark: state.newPlan.taskDescription,
          };
          const res = await taskManage.addCycleTask(data);
          if (res.resultStatus) {
            ElMessage.success(t("1274"));
            planTaskDialogShow.value = false;
            state.newPlan.planName = "";
            state.newPlan.taskDescription = "";
            state.newPlan.taskValue = "";
            state.newPlan.cycleMode = "";
            state.newPlan.activeStartTime = "";
            state.newPlan.activeEndTime = "";
            state.newPlan.cycleParamTime = "";
            state.newPlan.weekValue = "";
            state.newPlan.multipleDate = "";
            getTaskCycleList();
          }
        } else {
          return false;
        }
      });
    };

    function closeAddNewPlan() {
      planTaskDialogShow.value = false;
      state.newPlan.planName = "";
      state.newPlan.taskDescription = "";
      state.newPlan.taskValue = "";
      state.newPlan.cycleMode = "";
      state.newPlan.activeStartTime = "";
      state.newPlan.activeEndTime = "";
      state.newPlan.cycleParamTime = "";
      state.newPlan.weekValue = "";
      state.newPlan.multipleDate = "";
    }

    /* 选中日期 */
    function chooseDate(num) {
      if (state.chooseDateArr.includes(num)) {
        const index = state.chooseDateArr.findIndex((item) => {
          return item === num;
        });
        if (index !== -1) {
          state.chooseDateArr.splice(index, 1);
        }
      } else {
        state.chooseDateArr.push(num);
      }
      //日期排序
      state.chooseDateArr.sort(function (a, b) {
        return a - b;
      });

      state.newPlan.multipleDate = state.chooseDateArr.join(";");
    }

    return {
      closeDrawer,
      getTaskCycleList,
      tableData,
      planTaskDialogShow,
      showNewPlan,
      handleSelectionChange,
      batchDel,
      checkedIds,
      checkedStatu,
      exportData,
      removeTask,
      getCycleMenu,
      getCycleMenuList,
      getTaskMenuList,
      getTaskMenu,
      addNewPlan,
      chooseDate,
      getUserInfo,
      userInfos,
      updateStatus,
      status,
      stopStatus,
      ...toRefs(state),
      value,
      taskSelectList,
      getTaskSelectList,
      elFormRef,
      closeAddNewPlan
    };
  },
});
</script>

<style lang="less" scoped>
@keyframes dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  animation: dialog-fade-in 0.5s ease;
  z-index: 2;

  .header {
    p {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #33ddf7;
    }

    .close {
      color: #ffff;
      font-size: 25px;
    }

    .element_icon {
      font-size: 1.125rem;
    }
  }

  .nav {
    margin-top: 0.9375rem;
    display: flex;
    align-items: center;
    width: 100%;

    .icon_new {
      border-radius: 50%;
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.375rem;
      background: #ffff;
      color: #409eff;
      font-weight: bold;
      margin-right: 10px;
    }
  }

  .table_list {
    border-top: 10px solid black;
  }

  .name {
    color: #32d8f5;
    padding-bottom: 10px;
  }

  .common_p {
    color: #32d8f5;
    padding: 15px 0 10px 0;
  }

  .column_box {
    display: flex;
    flex-direction: row;
    .box_left {
      width: 100px;
    }
    .box_right {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  .date_box {
    position: absolute;
    left: -350px;
    bottom: 0;
    width: 340px;
    background: #fff;
    border-radius: 4px;
    z-index: 9999;

    ::before {
      content: "";
      position: absolute;
      top: 195px;
      right: -20px;

      transform: rotate(90deg);
      display: block;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
    }

    .box_header {
      color: #606266;
      font-size: 15px;
      padding: 5px 0;
      border-bottom: 1px solid #e4e7ed;

      h4 {
        text-align: center;
      }
    }

    .date_ul {
      padding: 0 15px;
      font-size: 12px;
      color: #606266;
      text-align: center;

      li {
        width: 44px;
        height: 44px;
        padding: 8px;
        cursor: pointer;

        div {
          width: 100%;
          height: 100%;
          padding: 2px;
          border-radius: 50%;

          span {
            display: block;
            width: 100%;
            height: 100%;
            line-height: 24px;
            margin: 0 auto;
            border-radius: 50%;
          }
        }
      }

      .chooseLi {
        div {
          box-shadow: 0px 0px 3px 0px #409eff;

          span {
            background: #409eff;
            color: #fff;
          }
        }
      }

      li:hover {
        color: #409eff;
      }
    }

    .box_footer {
      padding: 4px;
      border-top: 1px solid #e4e7ed;
    }
  }
}

//重写input框样式
/deep/ .el-input__wrapper {
  border: 1px solid #00f5ff;
  background: #001c31;
  box-shadow: 0 0 0 0;
  border-radius: 0;
  width: 100px;
}

/deep/ .el-input__inner {
  color: #fff;
}

/deep/ .el-input .el-input__count .el-input__count-inner {
  background: transparent;
}

.taskTipInput {
  /deep/.el-textarea__inner {
    height: 5rem;
    border: 1px solid #00f5ff;
    background: #001c31;
    border-radius: 0;
    box-shadow: 0 0 0 0;
    padding: 10px;
    color: #ffff;
  }
}

/deep/ .el-textarea .el-input__count {
  background: transparent;
}

.el_form {
  /deep/ .el-form-item__label {
    color: #32d8f5;
  }
  .empty_form_item {
    padding-top: 30px;
  }
  .date_form_item {
    position: relative;
    .date_box {
      bottom: -84px;
    }
  }
  .split {
    display: inline-block;
    height: 34px;
    line-height: 40px;
    padding: 0 3px;
  }
}
</style>
