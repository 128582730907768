<template>
  <div>
    <div class="navRight">
      <div class="navRight_header flex-sb">
        <p style="font-size: 1.25rem">{{ t('159') }}</p>
        <div class="flex-sb" style="height: 100%">
          <div class="icon_btn blue_btn" @click="exportData()" v-checkAuth="'task-task-export'">
            <el-icon class="element_icon"> <Upload /> </el-icon>&nbsp;{{ t('647') }}
          </div>
          <div
            class="icon_btn red_btn"
            style="margin-top: 2px"
            @click="batchDel" v-checkAuth="'task-task-delete'"
          >
            <el-icon class="element_icon"> <Delete /> </el-icon>&nbsp;{{ t('210') }}
          </div>
        </div>
      </div>
      <div class="navRight_footer">
        <search-bar
          class="search"
          :searchPlaceholder="t('414')"
          width="19.625rem"
          v-model:searchContent="queryForm.keyword"
          @on-search="getData(true)"
        />
        <el-button
          @click="showPlanTask()"
          type="primary"
          style="font-size: 10px; margin-left: 1.875rem"
          ><span class="iconfont"></span> {{ t('803') }}&nbsp;&nbsp;{{
            planTaskCount
          }}</el-button
        >
        <!-- <div>
          <span style="color: #35e1fb; padding: 0 10px 0 20px">{{ t('804') }}</span>
          <el-cascader
            class="common-input"
            style="width: 150px"
            v-model="taskFrom.region"
            placeholder="t('175')"
            :options="city"
            :props="cityCasDer"
            clearable
          />
        </div> -->
        <select v-model="choose" class="selection">
          <option class="item" value="AIRPORT">{{ t('164') }}</option>
          <option class="item" value="UAV">{{ t('487') }}</option>
        </select>
        <ele-select
          :lableshow="false"
          v-if="choose === '1'"
          :options="deviceList"
          :labelname="'deviceName'"
          :value="'sn'"
          v-model:selectVal="deviceList.sn"
          v-model:selectLable="deviceList.deviceName"
          @on-select="getData(true)"
        ></ele-select>
        <ele-select
          :lableshow="false"
          v-else
          :options="deviceList"
          :labelname="'deviceName'"
          :value="'sn'"
          v-model:selectLable="deviceList.deviceName"
          v-model:selectVal="deviceList.sn"
          @on-select="getData(true)"
        >
        </ele-select>
        <ele-select
          :label="t('180')"
          :options="memberList"
          :labelname="'username'"
          :value="'id'"
          v-model:selectVal="memberList.id"
          @on-select="getData(true)"
        />
        <el-popover
          placement="bottom-end"
          trigger="click"
          popper-class="nopadding_popover"
          :show-arrow="false"
        >
          <template #reference>
            <img class="hover_opacity" src="@/asset/img/shoudiantong.png" />
          </template>
          <div class="popover_content">
            <div class="content_top">
              <p>{{ t('411') }}:</p>
              <el-select
                v-model="createPeopleList.userId"
                @change="getData(true)"
                :placeholder="t('175')"
                :teleported="false"
                clearable
              >
                <el-option
                  v-for="item in createPeopleList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId"
                />
              </el-select>
            </div>
            <div class="line"></div>
            <div class="content_bottom">
              <p>{{ t('799') }}:</p>
              <div class="enter-date-picker">
                <div class="block">
                  <el-date-picker
                    @change="getData(true)"
                    v-model="date"
                    type="datetime"
                    :placeholder="t('774')"
                    :teleported="false"
                    style="width: 23.125rem"
                    format="YYYY/MM/DD hh:mm:ss"
                    value-format="YYYY-MM-DD hh:mm:ss"
                  />
                </div>
              </div>
              <p class="endtime">{{ t('800') }}:</p>
              <div class="enter-date-picker">
                <div class="block">
                  <el-date-picker
                    @change="getData(true)"
                    v-model="dateEnd"
                    type="datetime"
                    :placeholder="t('774')"
                    :teleported="false"
                    style="width: 23.125rem"
                    format="YYYY/MM/DD hh:mm:ss"
                    value-format="YYYY-MM-DD hh:mm:ss"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="container">
      <ele-table
        :tableColumnArray="tableColumnArray"
        :tableData="tableData"
        v-model:pageNo="queryForm.pageNo"
        v-model:pageSize="queryForm.pageSize"
        :total="queryForm.total"
        @handleNoChange="getData"
        @handleSizeChange="getData"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot="{ prop, row }">
          <span
            class="taskName"
            v-if="prop === 'taskName' && rowId !== row.taskId"
            @click="editAirName(row)"
          >
            {{ row.taskName }}
          </span>
          <el-input
            ref="inputRef"
            @blur="changeName(row)"
            type="text"
            v-if="prop === 'taskName' && rowId === row.taskId"
            v-model.trim="row.taskName"
            onkeyup="this.value=this.value.replace(/\ +/g, '')"
            maxlength="20"
          />
        </template>
        <template v-slot:buttonHanlder>
          <el-table-column :label="t('610')" align="center">
            <template v-slot="{ row }">
              <el-row>
                <el-col :span="12">
                  <span class="blue_btn" @click.stop="showTaskDetail(row)"
                    >{{ t('391') }}</span
                  >
                </el-col>
                <el-col :span="12">
                  <span class="blue_btn" @click.stop="jumpTaskRecord(row)"
                    >{{ t('805') }}</span
                  >
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </template>
      </ele-table>
    </div>
    <!-- 详情 -->
    <TaskDetail
      v-if="isDetail"
      v-model:isVisible="isDetail"
      :taskId="taskId"
      @updateDate="getData"
    >
    </TaskDetail>
    <!-- 计划任务 -->
    <PlanTask
      v-if="isPlanTask"
      @closeDrawer="closeDrawer"
      @reload="getPlanTask"
    >
    </PlanTask>
  </div>
</template>

<script>
import { t } from '../../languages';
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  watchEffect,
  nextTick,
} from "vue";
import TaskDetail from "../../components/page/data-manage/components/task-detail.vue";
import PlanTask from "../../components/page/data-manage/components/plan-task.vue";
import taskManage from "@/network/task";
import download from "@/network/download";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import "animate.css";
// import { store } from "@/store";
import dataRoute from "@/network/data_route";
// import {
//   findP,
//   flatten,
//   openRegionAdcode,
//   findChildren,
// } from "@/utils/openRegion";
export default defineComponent({
  components: {
    TaskDetail,
    PlanTask,
  },
  setup() {
    onMounted(() => {
      getData();
      getCreateList();
      getMemberList();
      getPlanTask();
    });
    const tableColumnArray = [
        { label: t("399"), prop: "taskName" },
        { label: t("400"), prop: "airwayName" },
        { label: t("401"), prop: "taskBind" },
        { label: t("402"), prop: "execCount" },
        { label: t("435"), prop: "execTime" },
        { label: t("413"), prop: "taskRemark" },
    ]
    const state = reactive({
      choose: "AIRPORT",
      isAirport: "",
      value: "", //创建人select框
      date: "", //开始日期
      dateEnd: "", //结束日期
      visible: false,
      queryForm: {
        taskId: "",
        keyword: "",
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
      taskFrom: {
        region: [], //地区
        cityName: "",
      },
      // cityCasDer: {
      //   value: "name",
      //   label: "title",
      // },
      // city: [], //地区
    });

    // fetch("json/area-tree.json")
    //   .then((d) => d.json())
    //   .then((res) => {
    //     const arr = store.state.system.openRegion;
    //     const isCountry = arr.some((item) => {
    //       return item === 1;
    //     });
    //     if (isCountry) {
    //       state.city = res[0].children;
    //     } else {
    //       const arr1 = arr.map((item) => {
    //         return findP(item, res[0].children);
    //       });
    //       const treeAdcode = openRegionAdcode(res[0].children); //这个树的全部adcode
    //       const arr2 = flatten(arr1); //需要的  ,这里还去少区数据
    //       const arr3 = arr2.map((item) => {
    //         return findChildren(item, res[0].children); //补上缺少的区adcode
    //       });

    //       const needAdcode = Array.from(new Set([...arr2, ...flatten(arr3)]));
    //       const notAdcode = treeAdcode.filter(
    //         (v) => needAdcode.findIndex((el) => el === v) === -1
    //       ); //去重过滤需要的adcode
    //       const city = deepCopyIsOpenRegion(res[0].children); //添加禁用属性
    //       const needCity = delIsOpenRegion(city);
    //       state.city = needCity;

    //       // 递归遍历修改数据
    //       function deepCopyIsOpenRegion(source) {
    //         const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
    //         for (const k in source) {
    //           if (typeof source[k] === "object") {
    //             target[k] = deepCopyIsOpenRegion(source[k]);
    //           } else {
    //             target[k] = source[k];
    //           }
    //         }
    //         if (notAdcode.length != 0) {
    //           notAdcode.map((item) => {
    //             if (target.adcode === item) {
    //               target.disabled = true;
    //             }
    //           });
    //         }
    //         return target;
    //       }
    //     }
    //     // 去除禁用的数据
    //     function delIsOpenRegion(source) {
    //       const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
    //       source.map((item) => {
    //         if (item.disabled != true) {
    //           target.push(item);
    //         }
    //       });
    //       return target;
    //     }
    //   });

    /*  详情  */
    const isDetail = ref(false);
    const taskId = ref("");

    const showTaskDetail = async (row) => {
      isDetail.value = true;
      taskId.value = row.taskId;
    };
    /* 计划任务 */
    const isPlanTask = ref(false);
    const showPlanTask = () => {
      isPlanTask.value = true;
    };
    /* 关闭计划任务 */

    const closeDrawer = () => {
      isPlanTask.value = false;
    };
    /* 获取任务列表 */
    const tableData = ref([]);
    async function getData(flag) {
      // 重置页数
      if (flag) {
        state.queryForm.pageNo = 1;
      }
      const res = await taskManage.getTaskList({
        deviceName: deviceList.value.sn,
        startTime: state.date,
        endTime: state.dateEnd,
        createUserId: createPeopleList.value.userId,
        memberId: memberList.value.id,
        keyword: state.queryForm.keyword,
        pageNo: state.queryForm.pageNo,
        pageSize: state.queryForm.pageSize,
      });
      if (res.resultStatus) {
        tableData.value = res.resultData.data;
        state.queryForm.total = res.resultData.total;
      }
    }

    // /* 地区选择后触发的函数 */
    // const cityChange = () => {
    //   getMemberList();
    //   getDeviceList();
    // };

    /* 获取计划任务数量 */
    const planTaskCount = ref();
    const getPlanTask = async () => {
      const res = await taskManage.getPlanTask();
      planTaskCount.value = res.resultData;
    };
    /* 根据区域获取人员下拉框列表 */
    const memberList = ref([]);
    const getMemberList = async () => {
      // let cityName = state.taskFrom.region;
      // state.taskFrom.cityName = `${cityName[0]};${cityName[1]};${cityName[2]}`; //拼接地区
      const res = await taskManage.getMemberList({
        // region: state.taskFrom.cityName,
      });
      memberList.value = res.resultData;
    };
    /* 根据区域获取设备下拉框列表 */
    const deviceList = ref([]);
    const getDeviceList = async () => {
      const res = await taskManage.getDeviceList({
        deviceType: state.isAirport,
      });
      deviceList.value = res.resultData;
    };
    /* 勾选Checkbox */
    const checkedIds = ref([]);
    function handleSelectionChange(selection) {
      const ids = [];
      for (let i = 0; i < selection.length; i++) {
        ids.push(selection[i].taskId);
      }
      checkedIds.value = ids;
    }

    /* 批量删除 */
    function batchDel() {
      if (checkedIds.value.length > 0) {
        ElMessageBox.confirm(
          t("722"),
          t("130"),
          {
            confirmButtonText: t("218"),
            cancelButtonText: t("217"),
          }
        )
          .then(async () => {
            const res = await taskManage.batchDelMember({
              ids: checkedIds.value,
            });
            if (res.resultStatus) {
              ElMessage.success(t("560"));
              getData();
            }
          })
          .catch(() => {
            ElMessage(t("549"));
          });
      } else {
        ElMessage.warning(t("659"));
      }
    }
    //获取创建人列表
    const createPeopleList = ref([]);
    const getCreateList = async () => {
      const res = await taskManage.getCreateList();
      createPeopleList.value = res.resultData;
    };
    //导出文件
    const exportData = async () => {
      await download.downloadSource(
        "/api/v1/task/export",
        {
          nestSn: deviceList.value.deviceName,
          startTime: state.date,
          endTime: state.dateEnd,
          memberId: memberList.value.id,
          keyword: state.queryForm.keyword,
        },
        "application/vnd.ms-excel",
        "get"
      );
    };
    watchEffect(() => {
      state.isAirport = state.choose;
      getDeviceList();
    });

    //悬浮修改任务名称
    const inputRef = ref(null);
    const rowId = ref(null);
    const editAirName = (rowData) => {
      if (flag.value) {
        rowId.value = rowData.taskId;
      }
      nextTick(() => {
        inputRef.value.focus();
      });
    };
    const flag = ref(true);
    const changeName = async (rowData) => {
      if (rowData.taskName.length === 0) {
        ElMessage.error(t("806"));
        flag.value = false;
        return;
      }
      let res = await taskManage.rewriteTaskName(
        rowData.taskId,
        rowData.taskName
      );
      if (res.resultStatus) {
        ElMessage.success({
          duration: 1000,
          message: t("714"),
        });
        rowId.value = "";
        flag.value = true;
      } else {
        flag.value = false;
      }
    };

    //记录跳转任务记录
    const router = useRouter();
    function jumpTaskRecord(rowData) {
      router.push({
        path: "/taskRecord",
        query: {
          taskId: rowData.taskId,
        },
      });
    }

    return {
      ...toRefs(state),
      isDetail,
      showTaskDetail,
      getData,
      tableData,
      getMemberList,
      // cityChange,
      exportData,
      batchDel,
      handleSelectionChange,
      checkedIds,
      memberList,
      deviceList,
      getDeviceList,
      getCreateList,
      createPeopleList,
      taskId,
      getPlanTask,
      planTaskCount,
      isPlanTask,
      showPlanTask,
      closeDrawer,
      jumpTaskRecord,
      rowId,
      editAirName,
      changeName,
      inputRef,
      tableColumnArray,
    };
  },
});
</script>

<style scoped lang="less">
.navRight {
  height: 6.5625rem;
  margin-left: 23.125rem;
  background: rgba(0, 28, 49, 0.8);
  padding: 1.0625rem 1.5625rem;

  .navRight_header {
    p {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #33ddf7;
    }

    .element_icon {
      font-size: 1.125rem;
    }
  }

  .navRight_footer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0.9375rem;
    height: 40px;

    .search {
      display: flex;
      align-items: center;
    }

    .selection {
      background: transparent;
      border: none;
      color: #35e1fb;
      outline: 0;
      padding: 4px 5px;
      margin-left: 1rem;
      box-shadow: 0;
    }

    img {
      position: absolute;
      right: 0;
    }
  }
}

.container {
  border-top: 10px solid black;
  padding: 0 20px;
  height: calc(100% - 7.0625rem);
  background: rgba(0, 28, 49, 0.8);

  .taskName {
    cursor: url("../../asset/img/edit-pen.png"), auto;
  }

  .taskName:hover {
    opacity: 0.7;
  }
}

//el-select的样式
.el-select {
  /deep/.el-input__wrapper {
    width: 23.125rem;
    border: 1px solid #36e5ff;
    border-radius: 0.25rem;
    background-color: @lightBackground;
    height: 2.5rem;
    box-shadow: 0 0 0 0;

    .el-input__inner {
      color: #fff;
    }
  }
}
</style>